import "./fonts.css" * {
  margin: 0px;
  font-family: "Poppins";
}

html {
  font-family: "Poppins";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

.clickable:hover {
  cursor: pointer;
}

div#app {
  /*min-width:1250px;*/
  font-family: "Poppins", "Open Sans KR", Georgia, sans-serif;
  color: #12171a;
  height: 100vh;
}

#app a,
#app a:hover {
  color: inherit;
  text-decoration: none;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__header {
}

.react-confirm-alert {
}

.react-confirm-alert-body {
  font-family: "Poppins";
  padding: 30px 50px;
  background-color: #fafafa;
  text-align: center;
  width: 460px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #333;
  border-radius: 4px;
}

.react-confirm-alert-body h1 {
  font-weight: 700;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.react-confirm-alert-button-group > button {
  padding: 10px 25px;
  width: 130px;
  background-color: black;
  text-align: center;
  border-radius: 100px;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Poppins";
}

.react-confirm-alert-button-group > button:first-child {
  margin-right: 15px;
}

[tooltip] {
  /*margin:20px 60px;
  position:relative;
  display:inline-block;*/
}
[tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip-position="left"]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}
[tooltip-position="top"]::before {
  left: 50%;
}
[tooltip-position="bottom"]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
[tooltip-position="right"]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 12px;
  min-width: 80px;
  border-radius: 5px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 99;
  opacity: 0;
  white-space: normal;
}

[tooltip-position="left"]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}
[tooltip-position="top"]::after {
  left: 50%;
}
[tooltip-position="bottom"]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}
[tooltip-position="right"]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: inline-block;
}

button {
  font-family: "Poppins Semibold";
  font-size: 15px;
}

button[disabled] {
  background-color: #f3f0f0;
}

.Input__Label {
  align-items: center;
}

input.form-control {
  height: 40px;
}

input.form-control::placeholder {
  color: #a0aec0;
}

input.form-control:hover {
  border-color: #cbd5e0;
}

input.form-control:focus {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

input.form-control.Input_Error {
  border-color: #d0021b;
}

input.form-control.Input_Error[disabled] {
  border-color: #e6edf0;
}

[disabled].form-control + .Error__Label {
  visibility: hidden;
}

input.form-control,
textarea.form-control {
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a;
}

div.Select-control {
  border-color: #e6edf0;
  height: 40px;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a;
}

.Select.is-disabled + .Error__Label {
  visibility: hidden;
}

.Select.is-disabled > .Select-control {
  border-color: #e6edf0;
}

div.Select-placeholder {
  padding-top: 2px;
}

.Select div {
  padding-top: 2px;
}

label {
  font-size: 15px;
  font-family: "Poppins Semibold";
  letter-spacing: 1px;
  color: #12171a;
}

.procedureForm {
  display: "flex";
  flex-wrap: "wrap";
}

.procedureFormControl {
  min-width: 150px !important;
  margin-right: 15px !important;
  min-height: 50px;
}

button.appointmentForm {
  border-radius: 30px;
  margin: 10px;
}

.popUpForm .title h2 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
}

.popUpForm .contentText {
  font-size: 14px;
  font-family: "Poppins";
}

button.patientForm {
  border-radius: 30px;
  margin-top: 22px;
}

button.formSubmit.right {
  border-radius: 30px;
  font-size: 14px;
  margin-left: 10px;
}

button.primary {
  background-color: #253239;
  color: white;
}

button.right {
  margin-left: 10px;
}

/*
  Video Chat Page
*/
.videoChatPage + .navigationBar {
  display: none !important;
}

.videoChatBoxes > div:not(:first-child) > div > div {
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 2;
  position: absolute;
}

.videoChatBoxes > div:first-child > div > div {
  height: 100% !important;
  width: 100% !important;
}

.videoChatBoxes > div:only-child > div > div {
  height: 100% !important;
  width: 100% !important;
}
.videoChatBoxes > div:first-child > div {
  height: 100% !important;
  width: 100% !important;
}

.videoChatBoxes > div:only-child > div {
  height: 100% !important;
  width: 100% !important;
}

.videoChatPage .videoChatTitleOverlay {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  box-align: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}

.videoChatPage .videoChatTitleContent {
  margin-top: 32px;
  min-width: 200px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.2);
}

.videoChatPage .videoChatButtonsOverlay {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  text-align: center;
  right: 0;
}

.videoChatButtons {
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  position: relative;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: transform 250ms ease-out;
  transition: transform 250ms ease-out;
}

.videoChatButtons > .endVideoChat {
  pointer-events: all;
  margin-bottom: 35px !important;
}

.videochat-subscriber {
  width: 48%;
  float: left;
}

.videochat-full {
  height: 700px;
  width: 100%;
}

.videochat-small {
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 2;
}

video.OT_video-element {
  object-fit: contain !important;
}
.react-datepicker__input-container {
  width: 100% !important;
  border: 0.5px solid rgba(123, 136, 135, 0.5) !important;
  padding: 10px;
  border-radius: 10px;
}

.personal_info input {
  height: 37px;
  border-radius: 10px;
  color: #7b8887;
}
.personal_info .css-1f09qeu:focus,
.css-1f09qeu[data-focus] {
  box-shadow: none !important;
}
.css-1ygl3x:focus,
.css-1ygl3x[data-focus] {
  box-shadow: none !important;
}
.css-1c2kwqi:focus,
.css-1c2kwqi[data-focus] {
  box-shadow: none !important;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px !important;
  width: 31px !important;
  border-radius: 31px !important;
  background-color: #e5f3f1 !important;
  color: black !important;
}
.rbc-overlay > * + * {
  display: block;
  height: 50px !important;
  width: 100% !important;
  border-radius: 0 !important;
  background-color: #e5f3f1 !important;
  color: black !important;
}
/* .rbc-event {
  height: 30px !important;
} */
.rbc-show-more {
  height: 31px !important;
  width: 31px !important;
  border-radius: 31px !important;
  background-color: #179b8b !important;
  color: white !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  /* background-color: green !important; */
  height: 30px !important;
  width: 30px !important;
  border-radius: 30px !important;
  color: white !important;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}
.fc-timeGridDay-view .fc-timegrid-event-harness-inset .fc-timegrid-event {
  background: none !important;
  border: none !important;
  /* width: 50% !important; */

  /* background: #e5f3f1 !important; */
  border-radius: 10px !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  background: none !important;
  border: none !important;
  width: 100% !important;
  /* display: flex !important;
  justify-content: start !important; */
  /* background: #e5f3f1 !important; */
  border-radius: 10px !important;
}

.fc-cell-content {
  height: 500px !important;
}
.fc-timegrid-more-link {
  background: none !important;
  border: none !important;
}

.fc-theme-standard .fc-popover {
  z-index: -550 !important;
}
.fc .fc-daygrid-event-harness {
  margin-left: -15px !important;
  margin-top: 0 !important;
}
.fc .fc-daygrid-event-harness img {
  border: 1px solid white !important;
}
.fc-direction-ltr .fc-daygrid-more-link {
  left: -15px !important;
  z-index: 10 !important;
}
.fc .fc-daygrid-day-frame {
  cursor: pointer !important;
  padding-left: 20px !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.fc-timegrid-event-harness {
  /* background-color: #e5f3f1 !important; */
  /* min-width: 100% !important; */
  /* width: 80%; */
  margin: 10px 0 10px 0 !important;
  border-radius: 10px !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}
.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.fc-timegrid-slots table tbody tr {
  height: 190px !important;
}

/* .fc-timegrid-cols table tbody td {
  width: 200px !important;
}
.fc .fc-scrollgrid table thead tr th {
  border-right: none !important;
  width: 200px !important;
} */
.fc-direction-ltr .fc-timegrid-more-link {
  /* display: flex !important;
  justify-content: end !important;
  align-items: flex-end !important; */
  margin-top: 60px !important;
}
.fc .fc-scrollgrid table thead tr {
  height: 75px !important;
}

.fc-scrollgrid-sync-inner {
  height: 100% !important;
}
.fc .fc-col-header-cell-cushion {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  color: #7b8887 !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  text-transform: uppercase;
}
.fc .fc-scrollgrid-liquid {
  border: none !important;
}
.fc .fc-scrollgrid-section-liquid > td {
  border: none !important;
}
.css-yk16xz-control {
  height: 39px !important;
  border-radius: 10px !important;
}
/* .css-g1d714-ValueContainer {
  height: 39px !important;
}
.css-g1d714-ValueContainer {
  position: initial !important;
} */
.fc-timegrid-divider .fc-cell-shaded {
  display: none !important;
}
.fc .fc-timegrid-divider {
  display: none !important;
}
.fc-daygrid-body-natural table tbody tr td {
  display: none !important;
}
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  border: none !important;
}
.css-1shclso {
  background-color: #fdac33 !important;
}
.upload_list .css-1shclso {
  background-color: "" !important;
}

.close-icon-drawer button {
  position: absolute;
  left: -40px;
  background-color: white;
}
.close-icon-drawer button:focus {
  box-shadow: none !important;
}
.close-icon-drawer button :active {
  background: white !important;
}
.video_preview_player video {
  width: 1500px !important;
  border-radius: 10px !important;
}
.slider_input {
  accent-color: #e95252 !important;
  cursor: pointer !important;
}
#react-doc-viewer {
  border-radius: 10px !important;
  height: 85vh !important;
  width: 90% !important;
}
.accordion_item {
  margin: 15px 0 15px 0;
  /* border: 1px solid black; */
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.input_prefix {
  display: flex !important;
  align-content: center !important;
}
.loaction_select .css-1uccc91-singleValue {
  color: #7b8887 !important;
}
.loaction_select .css-tlfecz-indicatorContainer {
  display: none !important;
}
.loaction_select .css-1gtu0rj-indicatorContainer {
  display: none !important;
}
.css-7t45ru {
  color: #7b8887 !important;
  border-color: #7b888780 !important;
}
.css-7t45ru:focus {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.header_search_input {
  color: #7b8887 !important;
  border-color: #7b888780 !important;
}
.header_search_input:focus,
.header_search_input[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.css-1ctltzx {
  color: #7b8887 !important;
  border-color: #7b888780 !important;
}
.css-1ctltzx:focus,
.css-1ctltzx[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.css-9m35w7 {
  color: #7b8887 !important;
  border-color: #7b888780 !important;
}
.css-9m35w7:focus,
.css-9m35w7[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.css-1fnjjho:focus,
.css-1fnjjho[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.css-y6babb {
  color: #7b8887;
  font-weight: 400;
}
.css-hhpi4p {
  color: #7b8887;
  font-weight: 400;
}
.css-1fnjjho {
  color: #7b8887;
  font-weight: 400;
}
.css-1uccc91-singleValue {
  color: #7b8887 !important;
  font-weight: 400 !important;
}
.css-15g60id {
  font-weight: 400 !important;
}
.css-gure8h {
  border-color: #7b888780 !important;
}

.input_field {
  color: #7b8887;
  font-weight: 400;
}
.input_field:focus,
.input_field[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}

.css-13tdi66-control[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}
.css-1o6p8xs:focus,
.css-1o6p8xs[data-focus] {
  box-shadow: none !important;
  border-color: #179b8b !important;
}

.custom_phoneinput {
  border: 1px solid #7b888780 !important;
  color: #7b8887 !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  font-size: 10px !important;
  /* height: 39px !important;
  width: 100%; */
}
.PhoneInputCountry {
  display: none !important;
}
.chakra-collapse {
  overflow: visible !important;
}
